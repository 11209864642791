import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

const SEO = ({ title, keywords }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            defaultTitle
            defaultKeywords
          }
        }
      }
    `
  );

  const {
    defaultTitle,
    defaultKeywords,
  } = site.siteMetadata;

  const seoProps = {
    title,
    keywords: keywords || defaultKeywords,
  };

  return (
    <Helmet
      titleTemplate={`%s | ${defaultTitle}`}
    >
      <title>{seoProps.title}</title>
      <meta name="keywords" content={seoProps.keywords} />
    </Helmet>

  );
};

SEO.propTypes = {
  title: PropTypes.string,
  keywords: PropTypes.string,
};

SEO.defaultProps = {
  title: '',
  keywords: null,
};

export default SEO;
